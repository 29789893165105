<template>
  <v-container v-if="currentPageIndex !== null">
    <v-dialog
      v-model="feedbackDialog"
      v-if="feedbackDialog"
      :max-width="$vuetify.breakpoint.smAndDown ? '100%' : '40%'"
      persistent
    >
      <feedback-dialog v-model="feedbackDialog" :assessmentId="assessmentId" />
    </v-dialog>
    <v-dialog
      v-if="welcomeDialog"
      v-model="welcomeDialog"
      :width="welcomeDialogWidth"
      transition="dialog-bottom-transition"
    >
      <welcome-dialog v-model="welcomeDialog" assessmentType="Burnout" />
    </v-dialog>
    <feedback-button
      v-model="feedbackDialog"
      :style="$vuetify.breakpoint.smAndDown ? '' : 'top:50%; right: 0%'"
    />
    <v-row class="align-self-start" align="center" justify="space-around">
      <v-col cols="12" md="5" class="px-0">
        <desk-assessment-stepper
          :schema="schema"
          :currentTheme="currentTheme"
        />
      </v-col>
      <v-col cols="11" md="6" lg="5">
        <speech-bubble :text="physioText" imageUrl="physioProfile.png" />
      </v-col>
    </v-row>
    <v-row v-if="currentPageIsNotLastPage" justify="center">
      <v-col cols="12" sm="10" md="6">
        <p
          id="title"
          class="text-h4 primary--text"
          :class="$vuetify.breakpoint.mdAndUp ? ' mx-10' : ''"
        >
          {{ $t(schema[currentPageIndex].title) }}
        </p>
        <v-row
          no-gutters
          justify="center"
          style="width: 100%; margin-top: 100px"
          align="center"
          ><v-img
            v-if="$vuetify.breakpoint.mdAndUp"
            id="image"
            :key="currentTheme"
            :src="
              require('@/assets/images/burnoutassessment/' +
                schema[currentPageIndex].pages[0].imageName)
            "
            contain
            :max-width="$vuetify.breakpoint.lgAndUp ? '70%' : '80%'"
        /></v-row>
      </v-col>
      <v-col cols="11" sm="10" md="6" :key="formContainerKey">
        <form-generator
          :key="currentTheme"
          :questions="schema[currentPageIndex].pages[0].questions"
          :fullResults="results"
          v-model="results[currentTheme]"
        />
      </v-col>
    </v-row>
    <v-row id="reportRow" v-else justify="center">
      <burnout-report
        id="burnoutReport"
        :burnoutResults="results"
        key="resultsPage"
        :assessmentId="assessmentId"
        :emailReportDialog="sendReportDialog"
        @closeEmailReportDialog="sendReportDialog = false"
      />
    </v-row>

    <v-row justify="center">
      <v-col sm="6" md="2" v-show="showBack">
        <v-btn
          id="back"
          outlined
          color="primary"
          @click="back()"
          large
          rounded
          width="100%"
        >
          {{ $t("buttons.back") }}
        </v-btn>
      </v-col>
      <v-col sm="6" md="2" v-show="currentPageIsNotLastPage">
        <v-btn
          id="next"
          color="primary"
          :loading="savingData"
          @click="next()"
          large
          rounded
          width="100%"
          data-cy="next"
        >
          {{ $t("buttons.next") }}
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FormGenerator from "@/components/form/FormGenerator.vue";
import DeskAssessmentStepper from "@/components/common/Stepper.vue";
import SpeechBubble from "@/components/common/SpeechBubble.vue";
import BurnoutReport from "./report/BurnoutReport.vue";
import WelcomeDialog from "@/components/common/WelcomeDialog.vue";
import VitrueTooltip from "@/components/common/VitrueTooltip.vue";
import { storeAssessmentData, storeBurnoutAssessmentScore } from "@/customApi";
import BurnoutScores from "@/services/burnout/burnout-scores.js";
import { mapGetters } from "vuex";
import DatapointGeneration from "@/services/assessment-results-datapoint-generation";
const FeedbackDialog = () =>
  import("@/components/common/Feedback/FeedbackDialog.vue");
import FeedbackButton from "@/components/common/Feedback/FeedbackButton.vue";
import AssessmentLoadingService from "@/services/assessment-loading.js";
import * as Constants from "@/constants/constants.js";

export default {
  name: "BurnoutAssessment",
  components: {
    FormGenerator,
    DeskAssessmentStepper,
    SpeechBubble,
    BurnoutReport,
    WelcomeDialog,
    VitrueTooltip,
    FeedbackDialog,
    FeedbackButton
  },
  props: {
    assessmentId: String,
    loadedResults: Object
  },
  data() {
    return {
      schema: null,
      sendReportDialog: false,
      formContainerKey: 0, // used to force re-render when loading in saved away values.
      currentPageIndex: null,
      //switch between these results objects and un/comment the initialiser to go between a pre-populated or blank questionnaire
      // results: null,
      results: null,
      welcomeDialog: false,
      signUpErrorMessage: null,
      savingData: false,
      feedbackDialog: false,
      blankResults: {
        exhaustion: {
          mentallyexhausted: 2,
          effort: 2,
          recoverenergy: 2,
          physicallyexhausted: 2,
          lackenergy: 2,
          activeatwork: 2,
          exerttired: 2,
          endofdayexhausted: 2
        },
        mentaldistance: {
          enthusiasm: 2,
          autopilot: 2,
          aversion: 2,
          indifferent: 2,
          cynical: 2
        },
        cognitiveimpairment: {
          focused: 2,
          thinkclearly: 2,
          forgetfuldistracted: 2,
          troubleconcentrating: 2,
          mistakes: 2
        },
        emotionalimpairment: {
          controlemotions: 2,
          recogniseemotions: 2,
          irritable: 2,
          upset: 2,
          overreact: 2
        },
        secondarysymptoms: {
          troublesleeping: 2,
          worry: 2,
          tense: 2,
          panicattacks: 2,
          crowds: 2,
          chestpain: 2,
          stomachcomplaint: 2,
          headaches: 2,
          musclepain: 2,
          sickoften: 2
        },
        results: {}
      },
      prepopulatedResults: {
        exhaustion: {
          mentallyexhausted: 3,
          effort: 4,
          recoverenergy: 3,
          physicallyexhausted: 2,
          lackenergy: 1,
          activeatwork: 4,
          exerttired: 4,
          endofdayexhausted: 3
        },
        mentaldistance: {
          enthusiasm: 0,
          autopilot: 1,
          aversion: 0,
          indifferent: 0,
          cynical: 0
        },
        cognitiveimpairment: {
          focused: 1,
          thinkclearly: 0,
          forgetfuldistracted: 0,
          troubleconcentrating: 2,
          mistakes: 1
        },
        emotionalimpairment: {
          controlemotions: 0,
          recogniseemotions: 0,
          irritable: 2,
          upset: 0,
          overreact: 1
        },
        secondarysymptoms: {
          troublesleeping: 2,
          worry: 1,
          tense: 1,
          panicattacks: 0,
          crowds: 0,
          chestpain: 0,
          stomachcomplaint: 0,
          headaches: 0,
          musclepain: 2,
          sickoften: 0
        },
        results: {}
      }
    };
  },
  async created() {
    this.schema = require("@/assets/json/BurnoutAssessment/BurnoutAssessmentQuestions.json");
    this.results = this.showPrepopulatedBurnoutResults
      ? this.prepopulatedResults
      : this.blankResults;

    let allowInProgressToBeSet = true;
    let loadedAssessment =
      await AssessmentLoadingService.getAssessmentStateAndResults(
        this.assessmentId,
        Constants.assessmentTypes.burnoutAssessment,
        allowInProgressToBeSet
      );

    if (loadedAssessment.redirectObject) {
      this.$router.push(loadedAssessment.redirectObject);
      return;
    }

    if (this.loadedResults) {
      this.results = this.loadedResults;
    } else {
      this.results = loadedAssessment.results ?? this.results;
    }

    this.currentPageIndex = this.results.isCompleted
      ? this.schema.length - 1
      : 0;

    this.formContainerKey++;
    this.welcomeDialog =
      !this.results.isCompleted &&
      this.currentPageIndex != this.schema.length - 1;
  },
  methods: {
    next() {
      this.saveCurrentResults();
      hj(
        "stateChange",
        this.$router.currentRoute.path + `/${this.currentPageIndex}`
      );
      //with the current setup we need all elements to load before scrolling
      this.$nextTick(() => this.$vuetify.goTo(0));
    },
    back() {
      this.currentPageIndex--;
      hj(
        "stateChange",
        this.$router.currentRoute.path + `/${this.currentPageIndex}`
      );
      //with the current setup we need all elements to load before scrolling
      this.$nextTick(() => this.$vuetify.goTo(0));
    },
    saveCurrentResults() {
      let newIndex = this.currentPageIndex + 1;
      let assessmentDataPointValues = [];
      this.savingData = true;

      for (const [key, value] of Object.entries(
        this.results[this.currentTheme]
      )) {
        DatapointGeneration.getAssessmentDataPointValues(
          newIndex,
          this.schema,
          0,
          key,
          value,
          assessmentDataPointValues,
          "Burnout"
        );
      }

      // if new index is going to be the last page then mark assessment as completed
      let assessmentDataPointDto = {
        AssessmentId: this.assessmentId,
        AssessmentSection: this.currentTheme,
        MarkAssessmentAsCompleted: newIndex == this.schema.length - 1,
        Values: assessmentDataPointValues,
        AssessmentType: Constants.assessmentTypes.burnoutAssessment
      };

      // save data to db & navigate if successful
      if (assessmentDataPointDto.Values.length > 0) {
        storeAssessmentData(assessmentDataPointDto)
          .then(() => {
            this.currentPageIndex = newIndex;
          })
          .catch(err => {})
          .finally(() => {
            this.savingData = false;
          });
        if (assessmentDataPointDto.MarkAssessmentAsCompleted) {
          let burnoutScores = BurnoutScores.getScores(this.results);
          const scoreDto = {
            AssessmentId: this.assessmentId,
            Overall: burnoutScores.overall,
            Exhaustion: burnoutScores.exhaustion,
            CognitiveImpairment: burnoutScores.cognitiveimpairment,
            EmotionalImpairment: burnoutScores.emotionalimpairment,
            MentalDistance: burnoutScores.mentaldistance,
            SecondarySymptoms: burnoutScores.secondarysymptoms
          };

          storeBurnoutAssessmentScore(scoreDto).catch(err => {});
        }
      } else {
        this.savingData = false;
        this.currentPageIndex = newIndex;
      }
    }
  },
  computed: {
    ...mapGetters(["showPrepopulatedBurnoutResults"]),
    currentTheme() {
      return this.hasCurrentPageIndex
        ? this.schema[this.currentPageIndex].theme
        : "";
    },
    hasCurrentPageIndex() {
      return this.currentPageIndex || this.currentPageIndex === 0;
    },
    physioText() {
      if (!this.hasCurrentPageIndex) {
        return "";
      }
      let options =
        this.schema[this.currentPageIndex].pages[0].speechBubbleText;
      if (this.currentPageIsNotLastPage) {
        return this.$t(
          this.schema[this.currentPageIndex].pages[0].speechBubbleText[0]
        );
      }
      let overallScore = BurnoutScores.getScores(this.results).overall;
      let risk = BurnoutScores.getRisk(overallScore, "overall");
      return this.$t(options[risk]);
    },
    currentPageIsNotLastPage() {
      return this.currentPageIndex < this.schema.length - 1;
    },
    showBack() {
      return this.currentPageIndex > 0 && this.currentPageIsNotLastPage;
    },
    welcomeDialogWidth() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return "100%";
      }
      return window.devicePixelRatio > 1.25 ? "80%" : "60%";
    }
  }
};
</script>
